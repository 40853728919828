<template>
  <section class="NotFound">
    <img class="NotFound__image" alt="Opa! Essa página não existe..." src="@/assets/images/errors/notFound.png">
    <h1 class="NotFound__text">
      Opa!<br>Essa página não existe...
    </h1>
  </section>
</template>

<script>
export default {
  name: 'Notfound'
}
</script>

<style lang="scss" scoped>
 .NotFound {
  background-image: url('../../../assets/images/tracking/bg_login.png');
  background-position: center;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__image {
    width: 90%;
    max-width: 845px;
   //  max-height: 50vh;
    margin: 0 auto;
    display: block;
  }
  &__text {
    font-size: $font-size-1xhuge;
    text-align: center;
    color: $color-neutral-softest;
  }
 }
</style>